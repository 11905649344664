th , td{
    text-align: center;
}

th {
background-color: #82b440;
color: white;
font-weight: 700;
font-size: 15px !important;
}
thead tr :first-child{
border-radius: 10px 0 0 0 ;
width: 40px;
}
thead tr :last-child{
    border-radius: 0 10px 0 0 ;
    }

table tbody {
    border: 1px solid black ;
    border-collapse: collapse;
}
::-webkit-scrollbar {
    width: 6px !important;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
  }
  ::-webkit-scrollbar-thumb {
    background: #A3A3A3 !important;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
  }